import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { usePermissionsStore } from "@/shared/store/permissions_store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/modules/login/ui/views/LoginView.vue"),
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: () => import("@/modules/dashboard/ui/views/DashboardView.vue"),
  // },
  {
    path: "/ciclo-pedido",
    name: "Ciclo de Vida do Pedido",
    meta: {
      requiresAuth: true,
      permission: 27,
    },
    component: () =>
      import("@/modules/ciclo_vida_pedido/ui/views/CicloVidaPedido.vue"),
  },
  {
    path: "/expedicao",
    name: "Expedição",
    meta: {
      requiresAuth: true,
      permission: 7,
    },
    component: () => import("@/modules/expedicao/ui/views/ExpedicaoView.vue"),
  },
  {
    path: "/producao",
    name: "Produção",
    meta: {
      requiresAuth: true,
      permission: 6,
      subtitle:
        "Acompanha a separação do insumo do pedido conforme operações de: pré-venda ou pronta entrega",
    },
    component: () => import("@/modules/producao/ui/views/ProducaoView.vue"),
  },
  {
    path: "/integracao",
    name: "Integração",
    meta: {
      requiresAuth: true,
      permission: 3,
      subtitle: "Valida as integrações dos pedidos entre a Loja e ERP Winthor",
    },
    component: () => import("@/modules/integracao/ui/views/IntegracaoView.vue"),
  },
  {
    path: "/financiamento",
    name: "Financiamento",
    meta: {
      requiresAuth: true,
      permission: 4,
      subtitle:
        "Instrui, Valida e acompanha a aprovação do crédito do pedido do cliente junto a órgãos financeiros",
    },
    component: () =>
      import("@/modules/financiamento/ui/views/FinanciamentoView.vue"),
  },
  {
    path: "/reconhecimento",
    name: "Reconhecimento",
    meta: {
      requiresAuth: true,
      permission: 5,
      subtitle:
        "Reconhece o pagamento do cliente final pelas vias de cobrança: boleto, pix, cartão e financiamento",
    },
    component: () =>
      import("@/modules/reconhecimento/ui/views/ReconhecimentoView.vue"),
  },
  {
    path: "/transporte",
    name: "Transporte",
    meta: {
      requiresAuth: true,
      permission: 8,
      subtitle:
        "Trata do andamento do agendamento do pedido junto a transportadora",
    },
    component: () => import("@/modules/transporte/ui/views/TransporteView.vue"),
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      requiresAuth: true,
      permission: 18,
    },
    component: () => import("@/modules/report/ui/views/ReportView.vue"),
  },
  {
    path: "/consulta-pedido",
    name: "Consulta de Pedido",
    meta: {
      requiresAuth: true,
      permission: 1,
    },
    component: () =>
      import("@/modules/consulta_pedido/ui/views/ConsultaPedidoView.vue"),
  },
  {
    path: "/detalhes/:id",
    name: "Detalhes do Pedido",
    meta: {
      requiresAuth: false,
    },
    props: true,
    component: () => import("@/shared/components/details_page/DetailsView.vue"),
  },
  {
    path: "/consulta-pedido/:id",
    name: "Consulta Detalhes",
    meta: {
      requiresAuth: true,
      permission: 2,
    },
    component: () =>
      import("@/modules/consulta_detalhes/ui/views/ConsultaDetalhesView.vue"),
  },
  {
    path: "/cadastro-usuario/:token",
    name: "Cadastro Usuario",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import("@/modules/cadastro_usuario/ui/views/CadastroUsuarioView.vue"),
  },
  {
    path: "/perfis",
    name: "Perfis",
    meta: {
      requiresAuth: true,
      permission: 30,
    },
    component: () => import("@/modules/perfis/ui/views/PerfisView.vue"),
  },
  {
    path: "/usuarios",
    name: "Usuários",
    meta: {
      requiresAuth: true,
      permission: 38,
    },
    component: () => import("@/modules/usuarios/ui/views/UsuariosView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuth = !!sessionStorage.getItem("access_token");
  const storePermissions = usePermissionsStore();
  const myPermissions = storePermissions.permissions;

  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  if (!isAuth) {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
    return;
  }

  if (to.path === "/" && isAuth) {
    next({ path: "/ciclo-pedido" });
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      myPermissions.some(
        (permission) => permission.id === (to.meta.permission as number)
      )
    ) {
      next();
    } else {
      return;
    }
  } else {
    next();
  }
});

export default router;
