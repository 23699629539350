import { defineStore } from "pinia";
import { ref, computed } from "vue";
import UserPermission from "@/shared/dtos/user_permission_dto";

export const usePermissionsStore = defineStore(
  "permissionsStore",
  () => {
    const permissions = ref<UserPermission[]>([]);

    const PermissionsEnum = computed(() =>
      permissions.value.reduce((enumValues, tipo) => {
        enumValues[tipo.slug] = tipo.id;
        return enumValues;
      }, {} as Record<string, number>)
    );

    return {
      permissions,
      PermissionsEnum,
    };
  },
  {
    persist: true,
  }
);
