import { usePermissionsStore } from "@/shared/store/permissions_store";

export class PermissionService {
  static hasPermission(permissionId: number): boolean {
    const storePermissions = usePermissionsStore();
    return storePermissions.permissions.some(
      (permission) => permission.id === permissionId
    );
  }
}
